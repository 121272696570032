import {
    NotActiveMessage,
    NotActiveOverlay
} from "components/notActive/NotActive.styled";

export default function NotActive(): JSX.Element {
    return (
        <>
            <NotActiveOverlay />
            <NotActiveMessage>
                <span>
                    <strong>
                        De ANWB Fijne Vakantiecheck is (voorlopig) gestopt.
                    </strong>
                    <br /> Je kunt{" "}
                    <a
                        href="https://www.anwb.nl/vakantie/reisvoorbereiding"
                        target="_blank"
                        rel="noreferrer"
                    >
                        hier
                    </a>{" "}
                    terecht voor jouw vakantievoorbereiding. <br /> <br />
                    <strong>
                        Op de hoogte blijven via de nieuwsbrief ANWB Vakantie?
                    </strong>{" "}
                    Meld je{" "}
                    <a
                        href="https://www.anwb.nl/nieuwsbrieven"
                        target="_blank"
                        rel="noreferrer"
                    >
                        hier
                    </a>{" "}
                    aan.
                </span>
            </NotActiveMessage>
        </>
    );
}
